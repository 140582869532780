import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Divider from '@atoms/Divider/Divider';
import Title from '@atoms/Title/Title';
import GenericPages from './GenericPages';

export default function GenericPagesCookie() {
    function renderGermanContent() {
        return (
            <>
                <p>
                    <span>
                        Zuletzt aktualisiert am: <strong>28.10.2020</strong>
                    </span>
                </p>

                <Divider spacing="m" invisible />

                <p>
                    <span>
                        Die Website{' '}
                        <a href="http://www.aepsy.com">www.aepsy.com</a> ("
                        <strong>Website</strong>") verwendet Cookies und
                        &auml;hnliche Tracking Technologien, um die
                        Funktionalit&auml;t der Website zu gew&auml;hrleisten
                        und das Nutzererlebnis zu verbessern.
                    </span>
                </p>
                <p>
                    <span>
                        Diese Cookie Richtlinie ("
                        <strong>Cookie Richtlinie"</strong>) zeigt auf, wie die
                        Aepsy AG ("<strong>Aepsy</strong>", "
                        <strong>uns</strong>
                        ", "<strong>wir</strong>" oder "<strong>unser</strong>")
                        Cookies und &auml;hnliche Technologien verwendet und
                        welche Wahlm&ouml;glichkeiten im Zusammenhang mit diesen
                        Technologien bestehen.
                    </span>
                </p>

                <Title tag="h2" size="m">
                    1 Was sind Cookies?
                </Title>

                <p>
                    <span>
                        Cookies sind Textdateien, die beim Besuch der Website
                        auf Ihr Ger&auml;t heruntergeladen werden. Cookies
                        erm&ouml;glichen es uns, Sie als Besucher
                        wiederzuerkennen, wenn Sie auf die Website
                        zur&uuml;ckkehren. Cookies helfen uns dabei, gewisse
                        Funktionalit&auml;ten der Website sicherzustellen und
                        den Webverkehr auf unserer Website zu analysieren. Wir
                        verwenden sowohl 'Session-Cookies' (die ablaufen, sobald
                        Sie Ihren Webbrowser schliessen) als auch 'Dauerhafte
                        Cookies' (die f&uuml;r eine bestimmte Zeit oder bis Sie
                        sie l&ouml;schen auf Ihrem Ger&auml;t verbleiben). Wir
                        verwenden in dieser Richtlinie das Wort "Cookie"
                        einheitlich zur Bezeichnung aller Art von Dateien,
                        welche auf die beschriebene Weise Informationen sammeln.{' '}
                    </span>
                </p>

                <Title tag="h2" size="m">
                    2 Plattform
                </Title>

                <p>
                    Sofern Sie Cookies, die von dieser Website (oder generell
                    auch von anderen Websites) gesetzt werden,
                    einschr&auml;nken, blockieren oder l&ouml;schen
                    m&ouml;chten, k&ouml;nnen Sie dies jederzeit &uuml;ber Ihre
                    Browsereinstellungen tun. Die 'Hilfe' Funktion Ihres Browser
                    bietet Ihnen weitere Informationen.
                </p>
                <p>
                    Bitte beachten Sie, dass eine Einschr&auml;nkung von Cookies
                    unter Umst&auml;nden dazu f&uuml;hren kann, dass Sie unsere
                    Website oder gewisse Funktionen unserer Website nicht oder
                    nur beschr&auml;nkt nutzen k&ouml;nnen.
                </p>

                <Title tag="h2" size="m">
                    3 Welche Cookies verwenden wir?
                </Title>

                <p>
                    Wir verwenden die folgenden Kategorien von Cookies f&uuml;r
                    die folgenden Funktionen:
                </p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p>
                                    <strong>Cookiename</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <strong>Anbieter/ Domain</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <strong>Speicherdauer</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <strong>Zweck</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <strong>Link</strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>_hjid</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://www.hotjar.com/">
                                        https://www.hotjar.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>1 Jahr</p>
                            </td>
                            <td>
                                <p>
                                    Hotjar-Cookie wird gesetzt, wenn der Kunde
                                    zum ersten Mal auf einer Seite mit einem
                                    Hotjar-Skript landet. Es wird verwendet, um
                                    die Hotjar-Benutzer-ID im Browser zu
                                    speichern. Auf diese Weise wird
                                    sichergestellt, dass das Nutzerverhalten bei
                                    nachfolgenden Besuchen der gleichen Website
                                    der gleichen Benutzer-ID zugeordnet wird.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                                        https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>_ga</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 Jahre</p>
                            </td>
                            <td>
                                <p>
                                    Google-Services-Cookie - Sammelt Google
                                    Analytics-Daten und identifiziert Nutzer (
                                    <a href="https://tools.google.com/dlpage/gaoptout/">
                                        https://tools.google.com/dlpage/gaoptout/
                                    </a>
                                    )
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://developers.google.com/analytics/devguides/collection/ga4/cookies-user-id">
                                        https://developers.google.com/analytics/devguides/collection/ga4/cookies-user-id
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>_gid</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 Tage</p>
                            </td>
                            <td>
                                <p>
                                    Google-Services-Cookie - Sammelt Google
                                    Analytics-Daten und identifiziert
                                    Nutzer&nbsp;
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>_ga_SHFN03B826</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>10 Minuten</p>
                            </td>
                            <td>
                                <p>H&auml;lt den Sitzungszustand aufrecht.</p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>__Secure-3PSID</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 Jahre</p>
                            </td>
                            <td>
                                <p>
                                    Erstellt ein Interessenprofil der
                                    Website-Besucher, um durch "Retargeting"
                                    relevante und personalisierte Anzeigen
                                    schalten zu k&ouml;nnen.&nbsp;
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>__Secure-3PAPISID</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 Jahre</p>
                            </td>
                            <td>
                                <p>
                                    Erstellt ein Interessenprofil der
                                    Website-Besucher, um durch "Retargeting"
                                    relevante und personalisierte Anzeigen
                                    schalten zu k&ouml;nnen.&nbsp;
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>__Secure-3PSIDCC</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 Jahre</p>
                            </td>
                            <td>
                                <p>
                                    Erstellt ein Profil der Interessen des
                                    Website-Besuchers, um relevante &amp;
                                    personalisierte Google-Werbung zu zeigen.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>1P_JAR</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p>
                                    Wird verwendet, um Website-Statistiken zu
                                    sammeln und die Konversionsraten zu messen.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>CONSENT</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>20 Jahre</p>
                            </td>
                            <td>
                                <p>
                                    Google-Services-Cookie - f&uuml;r die
                                    Zustimmung des Nutzers zur Verwendung von
                                    Cookies.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>SSID, APISID</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p>
                                    Wird von Google verwendet, um
                                    Benutzereinstellungen und Informationen von
                                    Google Maps zu speichern
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>_ga*</strong>
                                </p>
                                <p>
                                    <strong>__utm*</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p>
                                    Cookies, die mit __utm und _ga beginnen,
                                    stammen von Google Analytics und werden zur
                                    Aufzeichnung von Besucherstatistiken
                                    verwendet.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>HSID, SID</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 Jahre</p>
                            </td>
                            <td>
                                <p>
                                    Dieses Cookie wird von Google in Kombination
                                    mit der HSID verwendet, um ein
                                    Google-Nutzerkonto und die j&uuml;ngste
                                    Anmeldezeit zu &uuml;berpr&uuml;fen.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>SAPISID</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 Jahre</p>
                            </td>
                            <td>
                                <p>F&uuml;r Google Messungen</p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>SIDCC</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>3 Monate</p>
                            </td>
                            <td>
                                <p>
                                    Google-Services-Cookie - Sicherheits-Cookie
                                    zum Schutz der Nutzerdaten vor unbefugtem
                                    Zugriff
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>DV</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>1 Tag</p>
                            </td>
                            <td>
                                <p>
                                    Wird von Google Analytics verwendet, um
                                    verschiedene Informationen &uuml;ber die
                                    Benutzernavigation auf unserer Website zu
                                    sammeln. Zum Beispiel Besucherstandort,
                                    Besuchsdetails (Zeit, Seitenaufrufe usw.).
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>NID</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>6 Monate</p>
                            </td>
                            <td>
                                <p>
                                    Wird von Google Analytics verwendet, um
                                    verschiedene Informationen &uuml;ber die
                                    Benutzernavigation auf unserer Website zu
                                    sammeln. Zum Beispiel Besucherstandort,
                                    Besuchsdetails, Zeit oder Seitenaufrufe
                                    usw.&nbsp;
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>UULE</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 Tage</p>
                            </td>
                            <td>
                                <p>
                                    Wird von Google Analytics verwendet, um
                                    verschiedene Informationen &uuml;ber die
                                    Benutzernavigation auf unserer Website zu
                                    sammeln. Zum Beispiel Besucherstandort,
                                    Besuchsdetails, Zeit oder Seitenaufrufe usw.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>SEARCH_SAMESITE</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p>
                                    Dieses Cookie wird verwendet, um zu
                                    verhindern, dass der Browser dieses Cookie
                                    zusammen mit Cross-Site-Anfragen sendet.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>_fbp</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    .
                                    <a href="http://facebook.com">
                                        facebook.com
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>90 Tage</p>
                            </td>
                            <td>
                                <p>
                                    Wird von Facebook verwendet, um eine Reihe
                                    von Werbeprodukten anzubieten wie z.B.
                                    Echtzeit-Gebote von Drittanbietern.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://www.facebook.com/policy.php">
                                        https://www.facebook.com/policy.php
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>fr</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    .
                                    <a href="http://facebook.com">
                                        facebook.com
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>3 Monate</p>
                            </td>
                            <td>
                                <p>
                                    Enth&auml;lt eine Kombination aus Browser-
                                    und eindeutiger Benutzer-ID, die f&uuml;r
                                    gezielte Werbung verwendet wird.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://www.facebook.com/policy.php">
                                        https://www.facebook.com/policy.php
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>datr</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    .
                                    <a href="http://facebook.com">
                                        facebook.com
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 Jahre</p>
                            </td>
                            <td>
                                <p>
                                    Dieses Cookie identifiziert den Browser, der
                                    auf Facebook zugreift. Es ist nicht direkt
                                    mit dem individuellen Facebook-Nutzer
                                    verbunden. Gem&auml;ss Facebook wird es
                                    verwendet um bei der Sicherheit und
                                    verd&auml;chtigen Anmeldeaktivit&auml;ten zu
                                    helfen, insbesondere bei der Erkennung von
                                    Bots, die versuchen auf den Dienst
                                    zuzugreifen. Jedes mit Daten-Cookie
                                    verbundene Verhaltensprofil wird gem&auml;ss
                                    Facebook nach 10 Tagen gel&ouml;scht. Dieses
                                    Cookie wird auch &uuml;ber Like und andere
                                    Facebook-Buttons und -Tags gelesen, die auf
                                    verschiedenen Websites platziert sind.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://www.facebook.com/policy.php">
                                        https://www.facebook.com/policy.php
                                    </a>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Title tag="h2" size="m">
                    4 Welche Tracking Technologien verwenden wir?
                </Title>
                <Title tag="h3" size="s">
                    4.1 Google Analytics
                </Title>
                <p>
                    <span>
                        Wir k&ouml;nnen Trackingsysteme wie Google Analytics
                        oder &auml;hnliche Dienste auf unserer Website
                        verwenden. Dabei handelt es sich um Dienste, die von
                        Dritten bereitgestellt werden, die sich in jedem Land
                        weltweit befinden k&ouml;nnen (im Falle von Google
                        Analytics befindet sich Google LLC in den USA,
                        www.google.com) und die es uns erm&ouml;glichen, die
                        Nutzung unserer Website (auf anonymisierter Basis) zu
                        messen und auszuwerten. Zu diesem Zweck werden
                        permanente Cookies verwendet, die vom Dienstleister
                        gesetzt werden.
                    </span>
                </p>
                <p>
                    <span>
                        Die Dienstleister erhalten von uns keine
                        personenbezogenen Daten, k&ouml;nnen aber Ihre Nutzung
                        und Ihr Verhalten auf der Website verfolgen.
                    </span>
                </p>

                <Title tag="h3" size="s">
                    4.2 Google Tag Manager
                </Title>

                <p>
                    <span>
                        Die Website verwendet Google Tag Manager. Dieser Dienst
                        erm&ouml;glicht es, Website-Tags zu verwalten. Google
                        Tag Manager implementiert nur Tags. Das bedeutet, dass
                        keine Cookies gesetzt und keine pers&ouml;nlichen Daten
                        erfasst werden. Google Tag Manager l&ouml;st andere Tags
                        aus, die ihrerseits Daten aufzeichnen k&ouml;nnen.
                        Google Tag Manager greift jedoch nicht auf diese Daten
                        zu. Wenn Tags auf Domain- oder Cookie-Ebene deaktiviert
                        wurden, bleibt dies f&uuml;r alle Tracking-Tags
                        erhalten, wenn diese mit Google Tag Manager
                        implementiert werden. Weitere Informationen &uuml;ber
                        den Google Tag Manager finden Sie unter folgendem Link:{' '}
                        <a href="https://www.google.com/analytics/terms/tag-manager/">
                            https://www.google.com/analytics/terms/tag-manager/
                        </a>
                        .{' '}
                    </span>
                </p>

                <Title tag="h3" size="s">
                    4.3 HotJar
                </Title>

                <p>
                    <span>
                        Wir verwenden Hotjar, um die Bed&uuml;rfnisse unserer
                        Benutzer besser nachzuvollziehen und um Services sowie
                        das Nutzererlebnis zu optimieren. Hotjar erm&ouml;glicht
                        uns, das Verhalten unserer Nutzer auf unserer Website zu
                        verstehen (z.B. wie viel Zeit Sie auf welchen Seiten
                        verbringen, welche Links Sie anklicken usw.). Hotjar
                        verwendet Cookies und andere Technologien, um Daten
                        &uuml;ber das Verhalten unserer Nutzer und Ihrer
                        Ger&auml;te zu sammeln. Dies umfasst insbesondere die
                        IP-Adresse (die w&auml;hrend Ihrer Sitzung verarbeitet
                        und in anonymisierter Form gespeichert wird), die
                        Bildschirmgr&ouml;sse der Ger&auml;ts, den
                        Ger&auml;tetyp, Browserinformationen, den geografische
                        Standort (nur Land) und die bevorzugte Sprache, in der
                        unsere Website angezeigt wird. Hotjar speichert diese
                        Informationen in unserem Namen in einem
                        pseudonymisierten Benutzerprofil. Hotjar ist es
                        vertraglich untersagt, die in unserem Auftrag
                        gesammelten Daten zu verkaufen.
                    </span>
                </p>
                <p>
                    <span>
                        Weitere Informationen k&ouml;nnen Sie der Hotjar Website
                        entnehmen:{' '}
                        <a href="https://www.hotjar.com/">
                            https://www.hotjar.com/
                        </a>
                        .{' '}
                    </span>
                </p>

                <Title tag="h3" size="s">
                    4.4 Facebook Pixel
                </Title>

                <p>
                    <span>
                        Wir verwenden Facebook Pixel um das Verhalten von
                        Nutzern nachzuverfolgen, nachdem diese durch Klicken auf
                        eine Facebook Werbeanzeige auf unsere Website
                        weitergeleitet wurden. Dies erlaubt uns, die Wirksamkeit
                        von Facebook Werbeanzeigen f&uuml;r statistische Zwecke
                        sowie zur Marktforschung zu analysieren. Die so
                        erfassten Daten sind f&uuml;r uns anonym und wir
                        erhalten entsprechend keine personenbezogenen Daten. Die
                        Daten werden jedoch von Facebook gespeichert und
                        verarbeitet, wor&uuml;ber wir Sie entsprechend unserem
                        Kenntnisstand unterrichten. Facebook kann diese Daten
                        mit Ihrem Facebook-Konto verbinden und auch f&uuml;r
                        eigene Werbezwecke, entsprechend Facebooks
                        Datenverwendungsrichtlinie (
                        <a href="https://www.facebook.com/about/privacy/">
                            https://www.facebook.com/about/privacy/
                        </a>
                        ) verwenden. Weitere Informationen zum Facebook Pixel
                        finden Sie unter{' '}
                        <a href="https://www.facebook.com/business/learn/facebook-ads-pixel">
                            https://www.facebook.com/business/learn/facebook-ads-pixel
                        </a>
                        .{' '}
                    </span>
                </p>
                <p>
                    <span>
                        Wir behalten uns das Recht vor, bei Bedarf andere als
                        die im Abschnitt 4 genannten Trackingsysteme f&uuml;r
                        die angegebenen Zwecke einzusetzen.
                    </span>
                </p>

                <Title tag="h2" size="m">
                    5 Änderungen
                </Title>

                <p>
                    Wir k&ouml;nnen diese Cookie Richtlinie jederzeit
                    &auml;ndern und aktualisieren. Falls wir solche
                    &Auml;nderungen vornehmen, werden wir sie auf der Website
                    ver&ouml;ffentlichen. S&auml;mtliche &Auml;nderungen werden
                    mit ihrer Ver&ouml;ffentlichung wirksam. Wir empfehlen
                    Ihnen, diese Cookie Richtlinie regelm&auml;ssig zu
                    konsultieren.
                </p>

                <Title tag="h2" size="m">
                    6 Kontakt
                </Title>

                <p>
                    Wenn Sie Fragen zu dieser Cookie Richtlinie haben, wenden
                    Sie sich bitte an:
                </p>
                <p>Aepsy AG</p>
                <p>Seilergraben 53, 8001 Z&uuml;rich</p>
                <p>
                    <a href="mailto:hey@aepsy.com">hey@aepsy.com</a>
                </p>
            </>
        );
    }

    function renderEnglishContent() {
        return (
            <>
                <p>
                    <span>
                        Last updated on: <strong>28.10.2020</strong>
                    </span>
                </p>

                <Divider spacing="m" invisible />

                <p>
                    <span>
                        The <a href="http://www.aepsy.com">www.aepsy.com</a>{' '}
                        website ("
                        <strong>website</strong>") uses cookies and similar
                        tracking technologies to ensure the functionality of the
                        website and to improve the user experience.
                    </span>
                </p>
                <p>
                    <span>
                        This cookie policy ("<strong>Cookie Policy"</strong>)
                        indicates. how the Aepsy AG ("<strong>Aepsy</strong>", "
                        <strong>us</strong>
                        ", "<strong>we</strong>" or "<strong>our</strong>") uses
                        cookies and and similar technologies and what choices
                        are available in connection with these technologies. in
                        connection with these technologies exist.
                    </span>
                </p>

                <title tag="h2" size="m">
                    1 What are cookies?
                </title>

                <p>
                    <span>
                        Cookies are text files that are downloaded to your
                        Device are downloaded. Cookies enable us, recognize you
                        as a visitor when you return to the website. return to
                        the website. Cookies help us to provide certain
                        functionality of the website and to analyze web traffic
                        on our website. traffic on our website. We use both
                        session cookies (which expire as soon as you close your
                        web browser) and browser) and 'persistent cookies'
                        (which are stored for a certain period of time or until
                        time or until you delete them). We use the word 'cookie'
                        consistently throughout this policy to refer to to refer
                        to any type of file that collects information in the
                        manner described above. Collect information.{' '}
                    </span>
                </p>

                <title tag="h2" size="m">
                    2 platform
                </title>

                <p>
                    If you choose to block or refuse cookies that are set by
                    this website (or generally by other websites), you may
                    restrict, block or delete them. delete cookies, you can do
                    this at any time in your browser settings. browser settings.
                    The 'Help' function of your browser provides you more
                    information.
                </p>
                <p>
                    Please note that restricting cookies may, under certain
                    circumstances, result in your circumstances, you may not be
                    able to use our website or certain functions of website or
                    certain functions of our website or only to a limited
                    extent. can.
                </p>

                <title tag="h2" size="m">
                    3 What cookies do we use?
                </title>

                <p>
                    We use the following categories of cookies for the following
                    functions:
                </p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p>
                                    <strong>cookiename</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <strong>Provider/ domain</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <strong>Duration of storage</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <strong>Purpose</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <strong>Link</strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>_hjid</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://www.hotjar.com/">
                                        https://www.hotjar.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>1 year</p>
                            </td>
                            <td>
                                <p>
                                    Hotjar cookie is set when the customer lands
                                    for the first time on Lands on a page with a
                                    Hotjar script. It is used, to store the
                                    Hotjar user ID in the browser. This ensures
                                    that user behavior is tracked on subsequent
                                    visits to the same subsequent visits to the
                                    same website to the same user ID on
                                    subsequent visits to the same website.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                                        https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>_ga</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 years</p>
                            </td>
                            <td>
                                <p>
                                    Google services cookie - Collects Google
                                    Analytics data and Identifies users (
                                    <a href="https://tools.google.com/dlpage/gaoptout/">
                                        https://tools.google.com/dlpage/gaoptout/
                                    </a>
                                    )
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://developers.google.com/analytics/devguides/collection/ga4/cookies-user-id">
                                        https://developers.google.com/analytics/devguides/collection/ga4/cookies-user-id
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>_gid</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 days</p>
                            </td>
                            <td>
                                <p>
                                    Google services cookie - Collects Google
                                    Analytics data and identifies users&nbsp;
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>_ga_SHFN03B826</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>10 minutes</p>
                            </td>
                            <td>
                                <p>Maintains the session state.</p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>__Secure-3PSID</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 years</p>
                            </td>
                            <td>
                                <p>
                                    Creates an interest profile of website
                                    visitors in order to use "Retargeting" to
                                    serve relevant and personalized ads.
                                    k&ouml;nnen.&nbsp;
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>__Secure-3PAPISID</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 years</p>
                            </td>
                            <td>
                                <p>
                                    Creates an interest profile of website
                                    visitors in order to use "Retargeting" to
                                    serve relevant and personalized ads.
                                    k&ouml;nnen.&nbsp;
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>__Secure-3PSIDCC</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 years</p>
                            </td>
                            <td>
                                <p>
                                    Creates a profile of the website visitor's
                                    interests in order to Show relevant &amp;
                                    personalized Google advertising.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>1P_JAR</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p>
                                    Used to collect website statistics and
                                    measure the Conversion rates to measure.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>CONSENT</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>20 years</p>
                            </td>
                            <td>
                                <p>
                                    Google services cookie - for the user's
                                    consent to the use of cookies.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>SSID, APISID</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p>
                                    Used by Google to store user preferences and
                                    Information from Google Maps to store
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>_ga*</strong>
                                </p>
                                <p>
                                    <strong>__utm*</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p>
                                    Cookies that begin with __utm and _ga come
                                    from Google Analytics and are used to record
                                    visitor statistics. used.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>HSID, SID</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 years</p>
                            </td>
                            <td>
                                <p>
                                    This cookie is used by Google in combination
                                    with the HSID Used to identify a Google user
                                    account and the most recent login time.
                                    Login time to &uuml;berprpruef.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>SAPISID</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 years</p>
                            </td>
                            <td>
                                <p>F&uuml;r Google measurements</p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>SIDCC</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>3 months</p>
                            </td>
                            <td>
                                <p>
                                    Google services cookie - security cookie to
                                    protect the user data from unauthorized
                                    access
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>DV</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>1 tag</p>
                            </td>
                            <td>
                                <p>
                                    Used by Google Analytics to provide various
                                    Information &uuml;about user navigation on
                                    our Website to collect. For example, visitor
                                    location, Visit details (time, page views,
                                    etc.).
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>NID</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>6 months</p>
                            </td>
                            <td>
                                <p>
                                    Used by Google Analytics to provide various
                                    Information &uuml;about user navigation on
                                    our Website to collect. For example, visitor
                                    location, Visit details, time or page views,
                                    etc.&nbsp;
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>UULE</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 days</p>
                            </td>
                            <td>
                                <p>
                                    Used by Google Analytics to collect various
                                    Information &uuml;about user navigation on
                                    our Website to collect. For example, visitor
                                    location, Visit details, time or page views,
                                    etc.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>SEARCH_SAMESITE</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="http://google.com/">
                                        http://google.com/
                                    </a>
                                </p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p>
                                    This cookie is used to prevent the browser
                                    from sending sends this cookie along with
                                    cross-site requests.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://policies.google.com/privacy">
                                        https://policies.google.com/privacy
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>_fbp</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    .
                                    <a href="http://facebook.com">
                                        facebook.com
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>90 days</p>
                            </td>
                            <td>
                                <p>
                                    Used by Facebook to offer a range of
                                    advertising products such as real-time bids
                                    from third-party advertisers.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://www.facebook.com/policy.php">
                                        https://www.facebook.com/policy.php
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>fr</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    .
                                    <a href="http://facebook.com">
                                        facebook.com
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>3 months</p>
                            </td>
                            <td>
                                <p>
                                    Contains a combination of browser ID and
                                    unique User ID that is used for targeted
                                    advertising.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://www.facebook.com/policy.php">
                                        https://www.facebook.com/policy.php
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <strong>datr</strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    .
                                    <a href="http://facebook.com">
                                        facebook.com
                                    </a>
                                </p>
                            </td>
                            <td>
                                <p>2 years</p>
                            </td>
                            <td>
                                <p>
                                    This cookie identifies the browser that is
                                    accessing Facebook is accessing. It is not
                                    directly linked to the individual Facebook
                                    user. According to Facebook, it is used to
                                    help with security and suspicious login
                                    login activities, in particular to detect
                                    bots that try to access the service.
                                    Detection of bots attempting to access the
                                    service. Any behavioral profile associated
                                    with Data Cookie will be deleted after 10
                                    days, according to Facebook. This cookie is
                                    also read via Like and other Facebook
                                    buttons and tags Read, which are placed on
                                    various websites.
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://www.facebook.com/policy.php">
                                        https://www.facebook.com/policy.php
                                    </a>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Title tag="h2" size="m">
                    4 What tracking technologies do we use?
                </Title>
                <Title tag="h3" size="s">
                    4.1 Google Analytics
                </Title>
                <p>
                    <span>
                        We may use tracking systems such as Google Analytics or
                        &auml;hnliche services on our website use. These are
                        services provided by third parties that may be located
                        in any country in the world. any country in the world
                        (in the case of Google Analytics, Google LLC is located
                        in the USA). Analytics, Google LLC is located in the
                        USA, www.google.com) and which enable us to measure and
                        evaluate the use of our website (on an anonymous
                        anonymous basis) to measure and evaluate the use of our
                        website. For this purpose permanent cookies are used,
                        which are set by the service provider. are used.
                    </span>
                </p>
                <p>
                    <span>
                        The service providers do not receive any personal data
                        from us, but can track your use and behavior on the
                        website. track.
                    </span>
                </p>

                <title tag="h3" size="s">
                    4.2 Google Tag Manager
                </title>

                <p>
                    <span>
                        The website uses Google Tag Manager. This service makes
                        it possible to manage website tags. Google Tag Manager
                        only implements tags. This means that no cookies are set
                        and no personal data is collected. Google Tag Manager
                        triggers other tags, which in turn can record data.
                        data. However, Google Tag Manager does not access this
                        data. If tags have been deactivated at the domain or
                        cookie level, this remains for all tracking tags when
                        they are implemented with Google Tag Manager. For more
                        information about the Google Tag Manager can be found at
                        the following link:{' '}
                        <a href="https://www.google.com/analytics/terms/tag-manager/">
                            https://www.google.com/analytics/terms/tag-manager/
                        </a>
                        .{' '}
                    </span>
                </p>

                <title tag="h3" size="s">
                    4.3 HotJar
                </title>

                <p>
                    <span>
                        We use Hotjar to better understand the needs of our
                        users and to improve track and to optimize services and
                        the user experience. optimize. Hotjar enables us to
                        understand the behavior of our users on our website
                        (e.g. on our website (e.g. how much time you spend on
                        which pages, what links you pages, which links you click
                        on, etc.). Hotjar uses cookies and other technologies to
                        collect data about the behavior of our our users and
                        your devices. This includes IP address (which is
                        processed and stored anonymously during your session)
                        processed during your session and stored in anonymous
                        form), screen size screen size of the device, the type
                        of device, browser information, geographic location
                        (country only), and the preferred language preferred
                        language in which our website is displayed. Hotjar
                        stores this information on our behalf in a pseudonymous
                        user profile. Hotjar is contractually prohibited from,
                        sell the data collected on our behalf.
                    </span>
                </p>
                <p>
                    <span>
                        For more information, please see the Hotjar website:{' '}
                        <a href="https://www.hotjar.com/">
                            https://www.hotjar.com/
                        </a>
                        .{' '}
                    </span>
                </p>

                <title tag="h3" size="s">
                    4.4 Facebook Pixel
                </title>

                <p>
                    <span>
                        We use Facebook Pixel to track the behavior of users
                        track after they have been redirected to our website by
                        clicking on a Facebook Ad to be redirected to our
                        website. This allows us to analyze the effectiveness of
                        Facebook ads for statistical and market purposes and for
                        market research. The data collected in this way
                        anonymous for us and we do not receive any personal
                        data. personal data. However, the data is stored and
                        processed by Facebook and processed by Facebook, about
                        which we will inform you according to our inform you
                        according to our level of knowledge. Facebook may link
                        this data to your Facebook account and also for its own
                        advertising purposes, according to Facebook's data usage
                        policy (
                        <a href="https://www.facebook.com/about/privacy/">
                            https://www.facebook.com/about/privacy/
                        </a>
                        ). For more information about the Facebook Pixel, see{' '}
                        <a href="https://www.facebook.com/business/learn/facebook-ads-pixel">
                            https://www.facebook.com/business/learn/facebook-ads-pixel
                        </a>
                        .{' '}
                    </span>
                </p>
                <p>
                    <span>
                        We reserve the right, if necessary, to use tracking
                        systems other than those in sec. 4 mentioned tracking
                        systems for the specified purposes. use.
                    </span>
                </p>

                <title tag="h2" size="m">
                    5 Changes
                </title>

                <p>
                    We may change and update this cookie policy at any time.
                    update it. If we make such changes, we will post them on the
                    website. publish them on the website. All changes will be
                    posted on the website. will become effective upon their
                    publication. We recommend that you, to consult this Cookie
                    Policy on a regular basis.
                </p>

                <title tag="h2" size="m">
                    6 Contact
                </title>

                <p>
                    If you have any questions about this Cookie Policy, please
                    contact. to:
                </p>
                <p>Aepsy AG</p>
                <p>Seilergraben 53, 8001 Z&uuml;rich</p>
                <p>
                    <a href="mailto:hey@aepsy.com">hey@aepsy.com</a>
                </p>
            </>
        );
    }

    const { language } = useI18next();

    function renderContent() {
        switch (language) {
            case 'en':
                return renderEnglishContent();
            default:
                return renderGermanContent();
        }
    }

    return <GenericPages>{renderContent()}</GenericPages>;
}
